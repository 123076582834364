import './privacypolicy.css'

import * as React from 'react'
import { MdOutlinePrivacyTip } from 'react-icons/md'

import { FormControl, FormLabel, Input, Text, Tooltip } from '@chakra-ui/react'
import {
  Grid,
  Paper,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  Switch,
  withStyles,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import SaveDetector, {
  ISaveDetectorFormElements,
  noop,
  SAVE_DETECTOR_BAR_HEIGHT,
} from '../../components/saveDetector'
import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
// import Logger from '../../utils/logger'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { RouteProps, withRouter } from 'src/utils/withRouter'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useStore } from 'src/providers'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

// const { logError } = Logger('privacy-policy/index')

const styles: StyleRulesCallback = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    minWidth: 300,
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  dialog: {
    // backgroundColor: colors.darkGreyBlue
  },
  textField: {
    root: {
      color: theme.palette.common.white,
    },
    color: 'inherit',
    width: '100%',
    primaryTextColor: theme.palette.common.white,
    text: {
      primary: theme.palette.common.white,
    },
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.purple,
  },
  infoButton: {
    color: colors.medGreyBlue,
    fontSize: '1em',
    fontWeight: 550,
    cursor: 'pointer',
    opacity: 1,
    margin: '0 0 5px 3px',
  },
})

const divStyle = {
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbarIconCircle: { fontSize: 20, marginRight: 8 },
}

const tooltipText = {
  showPrivacyPolicyToggle:
    'Adding a Privacy Policy will display a consent screen prior to users engaging with your bot',
  customerFeedback:
    'All positive and negative feedback is collected and displayed below with the Dialogflow Session ID and a comment if applicable.',
  // privacyPolicy: `Adding a Privacy Policy will display a consent screen prior to users engaging with your bot`,
  sessionId: 'Dialogflow Session ID',
}

interface IPrivacyPolicyProps extends RouteProps {
  classes: any
  store?: RootStore
}

const PrivacyPolicy = ({ classes }: IPrivacyPolicyProps) => {
  const store = useStore()
  const { botId } = useParams()
  const [titleSnackbar, setTitleSnackbar] = useState(false)
  const [acceptLabelSnackbar, setAcceptLabelSnackbar] = useState(false)
  const [declineLabelSnackbar, setDeclineLabelSnackbar] = useState(false)
  const [declineMessageSnackbar, setDeclineMessageSnackbar] = useState(false)
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [successSnackbar, setSuccessSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const formElementsRef = useRef<ISaveDetectorFormElements>({
    bcPrivacyPolicyTitle: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBotLocal = store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBotLocal &&
          currentBotLocal.theme.privacyPolicy &&
          currentBotLocal.theme.privacyPolicy.bcPrivacyPolicyTitle !==
            e.target.value
        ) {
          try {
            await currentBotLocal.theme.privacyPolicy.changePrivacyPolicyTitle(
              e.target.value,
            )
            await currentBotLocal.patchTheme(currentBotLocal.theme)
            setTitleSnackbar(true)
          } catch (e) {
            setErrorSnackbar(true)
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    bcPrivacyPolicyAcceptLabel: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onChange: noop,
      onKeyPress: async (e) => {
        const currentBotLocal = store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBotLocal &&
          currentBotLocal.theme.privacyPolicy &&
          currentBotLocal.theme.privacyPolicy.bcPrivacyPolicyAcceptLabel !==
            e.target.value
        ) {
          try {
            await currentBotLocal.theme.privacyPolicy.changePrivacyPolicyAcceptLabel(
              e.target.value,
            )
            await currentBotLocal.patchTheme(currentBotLocal.theme)
            setAcceptLabelSnackbar(true)
          } catch (e) {
            setErrorSnackbar(true)
            return false
          }
        }
        return true
      },
    },
    bcPrivacyPolicyDeclineLabel: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBotLocal = store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBotLocal &&
          currentBotLocal.theme.privacyPolicy &&
          currentBotLocal.theme.privacyPolicy.bcPrivacyPolicyDeclineLabel !==
            e.target.value
        ) {
          try {
            await currentBotLocal.theme.privacyPolicy.changePrivacyPolicyDeclineLabel(
              e.target.value,
            )
            await currentBotLocal.patchTheme(currentBotLocal.theme)
            setDeclineLabelSnackbar(true)
          } catch (e) {
            setErrorSnackbar(true)
            return false
          }
        }
        return true
      },
      onChange: noop,
    },

    bcPrivacyPolicyDeclineMessage: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBotLocal = store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBotLocal &&
          currentBotLocal.theme.privacyPolicy &&
          currentBotLocal.theme.privacyPolicy.bcPrivacyPolicyDeclineMessage !==
            e.target.value
        ) {
          try {
            await currentBotLocal.theme.privacyPolicy.changePrivacyPolicyDeclineMessage(
              e.target.value,
            )
            await currentBotLocal.patchTheme(currentBotLocal.theme)
            setDeclineMessageSnackbar(true)
          } catch (e) {
            setErrorSnackbar(true)
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    privacyPolicyURL: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBotLocal = store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBotLocal &&
          currentBotLocal.theme.privacyPolicy &&
          currentBotLocal.theme.privacyPolicy.bcPrivacyPolicyURL !==
            e.target.value
        ) {
          try {
            await currentBotLocal.theme.privacyPolicy.changePrivacyPolicyURL(
              e.target.value,
            )
            await currentBotLocal.patchTheme(currentBotLocal.theme)
            setDeclineMessageSnackbar(true)
          } catch (e) {
            setErrorSnackbar(true)
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
  })

  const org = store?.organizations.current
  const me = store?.users.me
  const currentBot = store?.bots.currentBot

  const _closeSnackbar = useCallback((type: string) => {
    if (type === 'title') {
      setTitleSnackbar(false)
    }
    if (type === 'acceptLabel') {
      setAcceptLabelSnackbar(false)
    }
    if (type === 'declineLabel') {
      setDeclineLabelSnackbar(false)
    }
    if (type === 'declineMessage') {
      setDeclineMessageSnackbar(false)
    }

    if (type === 'success') {
      setSuccessSnackbar(false)
    }
    if (type === 'error') {
      setErrorSnackbar(false)
    }
  }, [])

  const _handleSnackbar = useCallback(
    (type: string, snackbarMessageValue: string) => {
      setSnackbarMessage(snackbarMessageValue)
      switch (type) {
        case 'success':
          setSuccessSnackbar(true)
          break

        case 'error':
          setErrorSnackbar(true)
          break
      }
    },
    [],
  )

  const _toggleShowPrivacyPolicy = useCallback(async () => {
    if (currentBot && currentBot.theme.privacyPolicy) {
      try {
        const showPrivacyPolicy =
          !currentBot.theme.privacyPolicy.bcShowPrivacyPolicy
        currentBot.theme.privacyPolicy.setShowPrivacyPolicy(showPrivacyPolicy)
        await currentBot.patchTheme(currentBot.theme)
        _handleSnackbar(
          'success',
          showPrivacyPolicy
            ? 'Enabled Privacy Policy'
            : 'Disabled Privacy Policy',
        )
      } catch (e) {
        _handleSnackbar('error', 'Please try again')
      }
    }
  }, [currentBot, _handleSnackbar])

  // const _changePrivacyPolicyURL = useCallback(async (event: any) => {
  //   const value = event.target.value
  //   const currentBotLocal = store?.bots.currentBot
  //   if (
  //     currentBotLocal &&
  //     currentBot.theme.css &&
  //     currentBot.theme.privacyPolicy?.bcPrivacyPolicyURL !== value
  //   ) {
  //     try {
  //       await currentBot.theme.privacyPolicy.changePrivacyPolicyURL(value)
  //       await currentBot.theme.css.changeVal(
  //         'bcShowPrivacyPolicyMenuItem',
  //         true,
  //       )
  //       await currentBot.patchTheme(currentBot.theme)
  //       _handleSnackbar(
  //         'success',
  //         `Successfully changed your bot's privacy policy url.`,
  //       )
  //       const dataLayer = {
  //         event: Events.privacyPolicy.success.type,
  //         eventName: Events.privacyPolicy.success.eventName,
  //         eventCode: Events.privacyPolicy.success.eventCode,
  //       }
  //       trackUserEvent(EventName.PortalAction, dataLayer)
  //     } catch (e) {
  //       logError(e)
  //       _handleSnackbar('error', 'Please try again.')
  //       const dataLayer = {
  //         event: Events.privacyPolicy.failure.type,
  //         eventName: Events.privacyPolicy.failure.eventName,
  //         eventCode: Events.privacyPolicy.failure.eventCode,
  //       }
  //       trackUserEvent(EventName.PortalAction, dataLayer)
  //       return false
  //     }
  //   }
  //   return true
  // }, [])

  useEffect(() => () => store.bots.setCurrentBotId(undefined), [store.bots])
  if (store?.bots.currentBotId !== botId) {
    store?.bots.setCurrentBotId(botId)
    return null
  }

  if (currentBot?.theme.css && org && me) {
    const botPrivacyPolicyURL =
      currentBot.theme.privacyPolicy.bcPrivacyPolicyURL

    if (!currentBot.theme.privacyPolicy) {
      return null
    }

    return (
      <Grid container={true} justify="center">
        <CreditCardCapture classes={{}} store={store} />
        <Grid container={true}>
          <Grid
            container={true}
            className="section-grid"
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              paddingBottom: SAVE_DETECTOR_BAR_HEIGHT,
            }}
          >
            <Grid container={true} justify="center">
              <Paper className="settings-paper">
                <Grid
                  container={true}
                  direction="column"
                  justify="space-between"
                  alignItems="stretch"
                >
                  <Grid
                    container={true}
                    alignItems="center"
                    className="paper-title-grid"
                  >
                    <Grid
                      container={true}
                      alignItems="baseline"
                      justify="space-between"
                    >
                      <Grid
                        container={true}
                        justify="space-between"
                        alignItems="center"
                      >
                        <div className="bot-profile-title-grid">
                          <MdOutlinePrivacyTip size="24px" />

                          {/* Privacy Policy  */}

                          <div className="paper-title">
                            Privacy Policy <strong>🆕</strong>
                          </div>
                          <Tooltip
                            label={tooltipText.showPrivacyPolicyToggle}
                            placement={'top'}
                            padding={5}
                          >
                            <a
                              href="https://docs.botcopy.com/#/basics/components?id=profile"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <InfoIcon className={classes.infoButton} />
                            </a>
                          </Tooltip>
                        </div>
                        <Switch
                          checked={
                            currentBot.theme.privacyPolicy.bcShowPrivacyPolicy
                          }
                          onChange={_toggleShowPrivacyPolicy}
                        />
                      </Grid>
                      <div className="privacy-paper-subtitle">
                        By default, the Privacy Policy Component is translated
                        to all languages that Dialogflow supports.
                        <br />
                        <br />
                        Any changes made here will display the Privacy Policy
                        component in the given language.
                      </div>
                    </Grid>
                  </Grid>
                  <div className="default-items-grid">
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Title
                      </FormLabel>
                      <Input
                        placeholder="To continue, please accept our Privacy Policy."
                        defaultValue={
                          currentBot.theme.privacyPolicy.bcPrivacyPolicyTitle
                        }
                        ref={
                          formElementsRef.current.bcPrivacyPolicyTitle.inputRef
                        }
                        onKeyPress={
                          formElementsRef.current.bcPrivacyPolicyTitle
                            .onKeyPress
                        }
                        onChange={
                          formElementsRef.current.bcPrivacyPolicyTitle.onChange
                        }
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      />
                    </FormControl>
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Privacy Policy Link
                      </FormLabel>
                      <Input
                        placeholder="https://www.botcopy.com/privacy"
                        defaultValue={botPrivacyPolicyURL}
                        ref={formElementsRef.current.privacyPolicyURL.inputRef}
                        onKeyPress={
                          formElementsRef.current.privacyPolicyURL.onKeyPress
                        }
                        onChange={
                          formElementsRef.current.privacyPolicyURL.onChange
                        }
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      />
                    </FormControl>
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Accept Button Label
                      </FormLabel>
                      <Input
                        placeholder="Accept"
                        defaultValue={
                          currentBot.theme.privacyPolicy
                            .bcPrivacyPolicyAcceptLabel
                        }
                        ref={
                          formElementsRef.current.bcPrivacyPolicyAcceptLabel
                            .inputRef
                        }
                        onKeyPress={
                          formElementsRef.current.bcPrivacyPolicyAcceptLabel
                            .onKeyPress
                        }
                        onChange={
                          formElementsRef.current.bcPrivacyPolicyAcceptLabel
                            .onChange
                        }
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      />
                    </FormControl>
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Decline Button Label
                      </FormLabel>
                      <Input
                        placeholder="Decline"
                        defaultValue={
                          currentBot.theme.privacyPolicy
                            .bcPrivacyPolicyDeclineLabel
                        }
                        ref={
                          formElementsRef.current.bcPrivacyPolicyDeclineLabel
                            .inputRef
                        }
                        onKeyPress={
                          formElementsRef.current.bcPrivacyPolicyDeclineLabel
                            .onKeyPress
                        }
                        onChange={
                          formElementsRef.current.bcPrivacyPolicyDeclineLabel
                            .onChange
                        }
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Decline Message
                      </FormLabel>
                      <Input
                        placeholder="To continue chatting, acceptance of our privacy policy is required"
                        defaultValue={
                          currentBot.theme.privacyPolicy
                            .bcPrivacyPolicyDeclineMessage
                        }
                        variant="outline"
                        ref={
                          formElementsRef.current.bcPrivacyPolicyDeclineMessage
                            .inputRef
                        }
                        onKeyPress={
                          formElementsRef.current.bcPrivacyPolicyDeclineMessage
                            .onKeyPress
                        }
                        onChange={
                          formElementsRef.current.bcPrivacyPolicyDeclineMessage
                            .onChange
                        }
                        w="inherit"
                        color={colors.lightGreyScale1200}
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                        mb={6}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={titleSnackbar}
          autoHideDuration={2000}
          onClose={() => _closeSnackbar('title')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {'Updated privacy policy title.'}
              </Grid>
            }
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={acceptLabelSnackbar}
          autoHideDuration={2000}
          onClose={() => _closeSnackbar('acceptLabel')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {`Updated the accept button's label.`}
              </Grid>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={declineLabelSnackbar}
          autoHideDuration={2000}
          onClose={() => _closeSnackbar('declineLabel')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {`Updated the decline button's label.`}
              </Grid>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={declineMessageSnackbar}
          autoHideDuration={2000}
          onClose={() => _closeSnackbar('declineMessage')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {'Updated the decline message.'}
              </Grid>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={declineMessageSnackbar}
          autoHideDuration={2000}
          onClose={() => _closeSnackbar('url')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {'Updated the privacy policy url.'}
              </Grid>
            }
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={successSnackbar}
          autoHideDuration={2500}
          onClose={(e) => _closeSnackbar('success')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {snackbarMessage}
              </Grid>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorSnackbar}
          autoHideDuration={2000}
          onClose={(e) => _closeSnackbar('error')}
        >
          <SnackbarContent
            style={divStyle.errorSnackbar}
            message={'There was an error saving your change. Please try again.'}
          />
        </Snackbar>

        <SaveDetector
          formElements={formElementsRef.current}
          length={Object.keys(formElementsRef.current).length}
        />
      </Grid>
    )
  }
  return (
    <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
  ) // TODO show a better loading screen
}

export default withRouter(withStyles(styles)(observer(PrivacyPolicy)))
