import './prompts.css'

import { each, map } from 'lodash-es'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdInfoOutline as InfoIcon } from 'react-icons/md'
import { Navigate } from 'react-router-dom'
import { createDraftExperiment, startExperiment, stopExperiment } from 'src/api'

import { SaveDetectorConfirmation, useSaveDetector } from '@botcopy/ui-shared'
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  Grid as ChakraGrid,
  HStack,
  Input,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  withStyles,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Close from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

import { SAVE_DETECTOR_BAR_HEIGHT } from '../../components/saveDetector'
import trackUserEvent from '../../components/trackEvents'
import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import { useStore } from '../../providers/StoreProvider'
import { GreeterStyle, IBot, IBotPrompt } from '../../models/bots'
import { Events } from '../../utils/gtm'
import CreditCardCapture from '../onboarding/CreditCardCapture'

import PromptCard from './Components/PromptCard'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import { RouteProps, withRouter } from 'src/utils/withRouter'
import generateObjectId from 'src/utils/generateObjectId'

const styles: StyleRulesCallback = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    padding: 40,
    width: '100%',
  },
  selectPromptTitle: {
    color: colors.offWhite,
    fontSize: '14px;',
    padding: '16px 24px',
  },
})

const divStyle = {
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  snackbarGrid: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  snackbarIconCircle: {
    fontSize: 20,
    marginRight: 8,
  },
}

const tooltipText = {
  botPrompts:
    'Contextualize how the bot reaches out to the user. Build intents in Dialogflow and set how they trigger below. Click to see docs.',
  refKeyName:
    'Set a global ref key that the chat window detects to pass data from your URL to your agent. Click to learn more.',
  defaultPromptBehavior: `Out of the box, the default prompt appears on the root page and pages that do not have a prompt. Set up prompts to configure this experience.
    You can also change this behavior and only show the greeter icon on pages instead.`,
}

interface IBotPromptsProps extends RouteProps {
  classes: any
  store?: RootStore
}

const BotPrompts: React.FunctionComponent<IBotPromptsProps> = ({
  classes,
  params,
}) => {
  const store = useStore()
  const [limitDialog, setLimitDialog] = useState(false)
  const [promptTotal, setPromptTotal] = useState(0)
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [successSnackbar, setSuccessSnackbar] = useState(false)
  const [currentBot, setCurrentBot] = useState<IBot | undefined>(undefined)
  const [defaultPromptDialog, setDefaultPromptDialog] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const initialValue = {
    refKeyName: currentBot?.refKeyName,
    prompts: toJS(currentBot?.prompts || []),
    showDefaultPromptOnPagesWithoutPrompt:
      currentBot?.theme.css.bcShowDefaultPromptOnPagesWithoutPrompt || false,
  }

  const [formElements, setFormElements] = useState<any>(initialValue)

  // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormElements((prev: any) => ({
  //     ...prev,
  //     showDefaultPromptOnPagesWithoutPrompt: event.target.value === 'true',
  //   }))
  // }

  const currentBotSync = store?.bots.getBotByIdSync(params.botId)
  const currentBotSyncString = JSON.stringify(currentBotSync)

  const userOnlyHasOnePrompt = formElements.prompts.length === 1

  useEffect(() => {
    if (currentBotSync) {
      setFormElements({
        refKeyName: currentBotSync.refKeyName,
        prompts: toJS(currentBotSync.prompts),
        showDefaultPromptOnPagesWithoutPrompt:
          currentBotSync.theme.css.bcShowDefaultPromptOnPagesWithoutPrompt,
      })
    }
  }, [currentBotSync, currentBotSyncString])

  const hasUnsavedChanges = useSaveDetector(initialValue, formElements)

  useEffect(() => {
    const fetchBotData = async () => {
      const bots = store?.bots
      if (params.botId && store?.bots.currentBotId !== params.botId) {
        store?.bots.setCurrentBotId(params.botId)
      }
      if (params.botId && store?.bots.currentBotId === params.botId) {
        setCurrentBot(store?.bots.currentBot)
      }

      const { entities } = bots
      let total = 0
      map(entities, (entity: IBot) => {
        total += entity.prompts.length
      })
      setPromptTotal(total)

      // Keep track of onboarding step
      //   const dataLayer = {
      //     event: Events.onboarding.type,
      //     eventName: Events.onboarding.eventName,
      //     eventCode: Events.onboarding.eventCode,
      //     label: Events.onboarding.promptLabel.label,
      //   }
      //   trackUserEvent('Onboarding Step', dataLayer)
      // }
    }

    fetchBotData()
  }, [store, params.botId])

  useEffect(
    () => () => {
      store?.bots.setCurrentBotId(undefined)
    },
    [store],
  )

  const getDefaultPrompt = useCallback(() => {
    if (!currentBot) {
      return undefined
    }
    return currentBot.prompts.find((prompt) => prompt.default === true)
  }, [currentBot])

  const defaultPrompt = getDefaultPrompt()
  const defaultPromptFormElement = formElements.prompts.find(
    (prompt: IBotPrompt) => prompt.default === true,
  )

  const defaultPromptUrlConflict = useMemo(
    () =>
      defaultPromptFormElement
        ? formElements.prompts.some(
            (p: any) =>
              p.default === false && p.url === defaultPromptFormElement.url,
          )
        : false,
    [defaultPromptFormElement, formElements.prompts],
  )

  const createBotPrompt = useCallback(async () => {
    const currentOrg = store?.organizations.current
    const user = store?.users.me

    if (!currentBot || !user || !currentOrg) return

    if (
      currentOrg.plan.planType === 'free' &&
      promptTotal >= currentOrg.plan.promptLimit
    ) {
      setLimitDialog(true)
      return
    }

    setFormElements((prev: any) => ({
      ...prev,
      prompts: [
        ...prev.prompts,
        {
          _id: generateObjectId(24, true),
          command: 'New_Command',
          message: '',
          type: 'training',
          url: '/',
          default: false,
          cuiMode: false,
          showMobile: true,
          showDesktop: true,
          esOutputContexts: [],
          cxSessionParams: [],
          suggestions: [],
          style: GreeterStyle.CLASSIC,
        },
      ],
      showDefaultPromptOnPagesWithoutPrompt:
        prev.showDefaultPromptOnPagesWithoutPrompt,
    }))

    const dataLayer = {
      event: Events.botPrompts.create.success.type,
      eventName: Events.botPrompts.create.success.eventName,
    }
    trackUserEvent('Bot Prompts', dataLayer)
  }, [currentBot, promptTotal, store?.organizations, store?.users.me])

  const handleSnackbar = useCallback(
    async (success: boolean, message?: string) => {
      if (message) setSnackbarMessage(message)
      if (success) {
        setSuccessSnackbar(true)
      } else {
        setErrorSnackbar(true)
      }
    },
    [setSnackbarMessage, setSuccessSnackbar, setErrorSnackbar],
  )

  const handleSave = useCallback(async () => {
    if (!currentBot) return

    try {
      const updatedFormElements = {
        ...formElements,
        theme: {
          ...currentBot.theme,
          css: {
            ...currentBot.theme.css,
            bcShowDefaultPromptOnPagesWithoutPrompt:
              formElements.showDefaultPromptOnPagesWithoutPrompt,
          },
        },
      }

      await currentBot.patchBotPrompts(updatedFormElements)
      handleSnackbar(true, 'Successfully saved your change.')
    } catch (e) {
      handleSnackbar(
        false,
        'There was an error saving your change. Please try again.',
      )
    }
  }, [currentBot, formElements, handleSnackbar])

  const showDefaultPromptDialog = useCallback(
    (val: boolean) => {
      setDefaultPromptDialog(val)
    },
    [setDefaultPromptDialog],
  )

  const closeSnackbar = useCallback(() => {
    setErrorSnackbar(false)
    setSnackbarMessage('')
    setSuccessSnackbar(false)
  }, [setErrorSnackbar, setSnackbarMessage, setSuccessSnackbar])

  const closeDialog = useCallback(() => {
    setLimitDialog(false)
    setDefaultPromptDialog(false)
  }, [setLimitDialog, setDefaultPromptDialog])

  const setUpMeeting = useCallback(() => {
    const user = store?.users.me
    if (user) {
      setLimitDialog(false)
      const url = `https://calendly.com/botcopy?name=${user.name}&email=${user.email}`
      window.open(url, '_blank')
    }
  }, [store?.users.me, setLimitDialog])

  const goToPlans = useCallback(() => {
    const dataLayer = {
      event: Events.subscription.changePlan.viewPlans.type,
      eventName: Events.subscription.changePlan.viewPlans.eventName,
      eventCode: Events.subscription.changePlan.viewPlans.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
    window.open(`${process.env.PUBLIC_URL}/account?showPlans=true`, '_blank')
  }, [])

  const handleDefaultPromptSelected = useCallback(
    async (selectedPrompt: IBotPrompt) => {
      if (selectedPrompt.type === 'preset' && !selectedPrompt.message) {
        return handleSnackbar(
          false,
          'A preset prompt must have a message before it is assigned as the default',
        )
      }

      const selectedPromptIndex = formElements.prompts.findIndex(
        (prompt: any) => prompt._id === selectedPrompt._id,
      )
      setFormElements((prev: any) => ({
        ...prev,
        prompts: [
          ...prev.prompts
            .slice(0, selectedPromptIndex)
            .map((p: any) => ({ ...p, default: false })),
          {
            ...selectedPrompt,
            default: true,
          },
          ...prev.prompts
            .slice(selectedPromptIndex + 1)
            .map((p: any) => ({ ...p, default: false })),
        ],
      }))

      setDefaultPromptDialog(false)
    },
    [formElements, handleSnackbar],
  )

  const handleExperimentStart = useCallback(
    (prompt: any) => async () => {
      if (!(currentBot && prompt?.lastExperiment?._id)) {
        // impossible case
        return
      }

      await startExperiment(
        currentBot._id,
        prompt._id,
        prompt.lastExperiment._id,
      )
      await store?.bots.fetchBots()
    },
    [currentBot, store],
  )

  const handleExperimentStop = useCallback(
    (prompt: any) => async () => {
      if (!(currentBot && prompt?.lastExperiment?._id)) {
        return
      }

      await stopExperiment(
        currentBot._id,
        prompt._id,
        prompt.lastExperiment._id,
      )
      await createDraftExperiment(
        currentBot._id,
        prompt._id,
        prompt.lastExperiment.casesPM.map((casePM: any) => casePM.message),
      )
      await store?.bots.fetchBots()
    },
    [currentBot, store],
  )

  const org = store?.organizations.current
  const me = store?.users.me
  if (!org || !me) {
    return <Navigate to="/" />
  }

  if (currentBot) {
    return (
      <Box>
        <CreditCardCapture classes={{}} store={store} />
        <Container maxWidth="1180px" p={8}>
          <Text textStyle="h5" fontSize="28px" mb="33px">
            Bot Prompt Settings
          </Text>
          <ChakraGrid height="100%" templateColumns="repeat(2, 1fr)" gap={4}>
            <Card
              padding={8}
              bg={colors.lightGreyScale100}
              border="1px solid"
              borderRadius="16px"
              height="100%"
              justifyContent="flex-start"
              borderColor={colors.lightGreyScale800}
            >
              <Grid
                container={true}
                direction="column"
                justify="space-between"
                alignItems="stretch"
              >
                <Flex justify="space-between">
                  <Text textStyle="h5" fontSize="21px" mb={6}>
                    Prompt Settings
                  </Text>
                  <Tooltip
                    label={tooltipText.defaultPromptBehavior}
                    placement={'top'}
                    padding={5}
                  >
                    <a
                      href="https://docs.botcopy.com/#/basics/bot-prompts"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                    </a>
                  </Tooltip>
                </Flex>
                <div className="default-items-grid">
                  <Button
                    onClick={() => showDefaultPromptDialog(true)}
                    leftIcon={<EditRoundedIcon fontSize="small" />}
                    variant="light"
                    maxWidth="250px"
                    isDisabled={userOnlyHasOnePrompt}
                  >
                    Change Default Prompt
                  </Button>
                  <Box marginBottom="30px" marginTop="5px">
                    {defaultPromptFormElement ? (
                      <Text fontSize="sm" color={colors.medGreyBlue}>
                        Current default:{' '}
                        {defaultPromptFormElement.type === 'preset'
                          ? defaultPromptFormElement.message
                          : defaultPromptFormElement.command}
                      </Text>
                    ) : (
                      <Text fontSize="sm" color={colors.medGreyBlue}>
                        No default prompt set
                      </Text>
                    )}
                  </Box>
                  <FormGroup>
                    <Tooltip
                      label="You only have one bot prompt"
                      placement="bottom"
                      isDisabled={!userOnlyHasOnePrompt}
                    >
                      {/* This box is here to appease Chakra UI's tooltip.
                      If you switch the form to use Chakra UI, you can remove the box element below. */}
                      <Box>
                        <FormLabel
                          style={{
                            fontSize: '1em',
                            color: colors.medGreyBlue,
                          }}
                        >
                          Pages with no prompt show:
                        </FormLabel>
                        <RadioGroup
                          row={true}
                          value={
                            formElements.showDefaultPromptOnPagesWithoutPrompt
                          }
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement
                            setFormElements((prev: any) => ({
                              ...prev,
                              showDefaultPromptOnPagesWithoutPrompt:
                                target.value === 'true',
                            }))
                          }}
                        >
                          <FormControlLabel
                            label="Default Prompt & Greeter"
                            control={
                              <Radio
                                checked={
                                  formElements.showDefaultPromptOnPagesWithoutPrompt ||
                                  userOnlyHasOnePrompt
                                }
                                disabled={userOnlyHasOnePrompt}
                                value="true"
                              />
                            }
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  !formElements.showDefaultPromptOnPagesWithoutPrompt &&
                                  !userOnlyHasOnePrompt
                                }
                                disabled={userOnlyHasOnePrompt}
                                value="false"
                              />
                            }
                            label="Greeter"
                          />
                        </RadioGroup>
                      </Box>
                    </Tooltip>
                  </FormGroup>
                </div>
              </Grid>
            </Card>
            <Card
              padding={8}
              bg={colors.lightGreyScale100}
              border="1px solid"
              borderRadius="16px"
              height="100%"
              justifyContent="flex-start"
              borderColor={colors.lightGreyScale800}
            >
              <Flex justify="space-between">
                <Text textStyle="h5" fontSize="21px" mb={6}>
                  Ref Parameter Detection
                </Text>
                <Tooltip
                  label={tooltipText.refKeyName}
                  placement={'top'}
                  padding={5}
                >
                  <a
                    href="https://docs.botcopy.com/#/advanced/url-ref-parameter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                  </a>
                </Tooltip>
              </Flex>

              <ChakraFormControl>
                <ChakraFormLabel
                  as={Text}
                  textStyle="overline"
                  casing="uppercase"
                >
                  Global Ref Key
                </ChakraFormLabel>
                <Input
                  placeholder={'ref'}
                  value={formElements.refKeyName}
                  onChange={(e) =>
                    setFormElements((prev: any) => ({
                      ...prev,
                      refKeyName: e.target.value,
                    }))
                  }
                  type="text"
                  size="lg"
                  variant="outline"
                  h="56px"
                />
              </ChakraFormControl>
            </Card>
          </ChakraGrid>

          <ChakraGrid
            templateColumns="1"
            style={{
              paddingBottom: SAVE_DETECTOR_BAR_HEIGHT,
              position: 'relative',
            }}
          >
            <HStack>
              <Text textStyle="h5" my="33px">
                Bot Prompts
              </Text>
              <Tooltip
                label={tooltipText.botPrompts}
                placement={'top'}
                padding={5}
              >
                <a
                  href="https://docs.botcopy.com/#/basics/bot-prompts"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                </a>
              </Tooltip>
            </HStack>
            <ChakraGrid gap={6}>
              {/* Add a new prompt  */}
              <Button
                variant="light"
                border="1px solid"
                borderRadius="16px"
                borderColor={colors.lightGreyScale800}
                p={8}
                textStyle="h5"
                fontSize="21px"
                h="100px"
                key="btn-add-a-prompt"
                onClick={createBotPrompt}
              >
                + Add New Prompt
              </Button>
              {defaultPrompt && defaultPromptFormElement ? (
                <PromptCard
                  classes={classes}
                  store={store}
                  currentBot={currentBot}
                  initialPrompt={defaultPrompt}
                  promptFormElements={defaultPromptFormElement}
                  hasUnsavedChanges={hasUnsavedChanges}
                  isDefaultPrompt={true}
                  showDefaultPromptDialog={showDefaultPromptDialog}
                  duplicateURL={defaultPromptUrlConflict}
                  onExperimentStart={handleExperimentStart(defaultPrompt)}
                  onExperimentStop={handleExperimentStop(defaultPrompt)}
                  onFormElementsChange={(updatedPrompt: any) => {
                    const updatedPromptIndex = formElements.prompts.findIndex(
                      (prompt: any) => prompt._id === updatedPrompt._id,
                    )
                    setFormElements((prev: any) => ({
                      ...prev,
                      prompts: [
                        ...prev.prompts.slice(0, updatedPromptIndex),
                        updatedPrompt,
                        ...prev.prompts.slice(updatedPromptIndex + 1),
                      ],
                    }))
                  }}
                />
              ) : (
                <Paper
                  className="select-default-prompt"
                  onClick={() => setDefaultPromptDialog(true)}
                >
                  Select a Default Prompt
                  <EditIcon fontSize="small" />
                </Paper>
              )}

              {initialValue.prompts?.length &&
                map(
                  formElements.prompts,
                  (prompt: IBotPrompt, i: number, a: IBotPrompt[]) => {
                    if (prompt.default) {
                      return
                    }
                    let duplicateURL = false
                    each(a, (checkUrlPrompt, j) => {
                      if (j === i) return
                      if (checkUrlPrompt.url === prompt.url) {
                        duplicateURL = true
                      }
                    })
                    return (
                      <PromptCard
                        key={prompt._id}
                        classes={classes}
                        store={store}
                        currentBot={currentBot}
                        initialPrompt={initialValue.prompts?.[i]}
                        promptFormElements={formElements.prompts[i]}
                        hasUnsavedChanges={hasUnsavedChanges}
                        isDefaultPrompt={false}
                        onDeletePrompt={() => {
                          setFormElements((prev: any) => ({
                            ...prev,
                            prompts: [
                              ...prev.prompts.slice(0, i),
                              ...prev.prompts.slice(i + 1),
                            ],
                          }))
                        }}
                        onExperimentStart={handleExperimentStart(
                          initialValue.prompts?.[i],
                        )}
                        onExperimentStop={handleExperimentStop(
                          initialValue.prompts?.[i],
                        )}
                        showDefaultPromptDialog={showDefaultPromptDialog}
                        duplicateURL={duplicateURL}
                        onFormElementsChange={(updatedPrompt: any) => {
                          const updatedPomptIndex =
                            formElements.prompts.findIndex(
                              (p: any) => p._id === updatedPrompt._id,
                            )
                          setFormElements((prev: any) => ({
                            ...prev,
                            prompts: [
                              ...prev.prompts.slice(0, updatedPomptIndex),
                              updatedPrompt,
                              ...prev.prompts.slice(updatedPomptIndex + 1),
                            ],
                          }))
                        }}
                      />
                    )
                  },
                )}
            </ChakraGrid>
          </ChakraGrid>
        </Container>

        {/* DIALOGS AND SNACKBARS */}

        {/* PROMPT LIMIT DIALOG */}
        <Dialog
          open={limitDialog}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle id="alert-dialog-title">
              You've reached your prompt limit.
            </DialogTitle>
            <Close
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '5px 5px 0 0',
                cursor: 'pointer',
              }}
              onClick={closeDialog}
            />
          </Grid>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontWeight: 600 }}
            >
              Get unlimited bots and prompts by subscribing to our metered
              billing plan! Only three cents per engagement a month.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="light" onClick={setUpMeeting}>
              Set up a call
            </Button>
            <Button onClick={goToPlans}>Choose a Plan</Button>
          </DialogActions>
        </Dialog>

        {/* DEFAULT PROMPT DIALOG */}
        <Dialog
          open={defaultPromptDialog}
          onBackdropClick={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
        >
          <Grid className="default-prompt-dialog">
            <DialogTitle
              className={classes.selectPromptTitle}
              id="alert-dialog-title"
            >
              {defaultPromptFormElement ? (
                <Text textStyle="subtitle1">
                  Default Prompt
                  <div className="default-prompt-title">
                    {defaultPromptFormElement.type !== 'preset'
                      ? defaultPromptFormElement.command
                      : defaultPromptFormElement.message}
                  </div>
                  <div className="default-prompt-title-type">
                    Type: {defaultPromptFormElement.type}
                  </div>
                  <div className="default-prompt-title-url">
                    Url: {defaultPromptFormElement.url}
                  </div>
                </Text>
              ) : (
                <Text textStyle="subtitle1">
                  Default Prompt
                  <div className="default-prompt-title">None Selected</div>
                </Text>
              )}
            </DialogTitle>
            <DialogContent>
              <Text textStyle="subtitle1">Prompts</Text>
              <FormControl
                style={{
                  display: 'block',
                  marginTop: '10px',
                  backgroundColor: colors.darkGreyBlue,
                }}
              >
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: '300px',
                    minWidth: '515px',
                    overflowY: 'auto',
                    backgroundColor: colors.darkGreyBlue,
                  }}
                >
                  {formElements.prompts.length >= 1 ? (
                    <Grid container={true} justify="center" direction="column">
                      {map(
                        formElements.prompts,
                        (
                          prompt: IBotPrompt,
                          index: number,
                          array: IBotPrompt[],
                        ) => {
                          if (prompt.default) {
                            return
                          }
                          return (
                            <Grid
                              key={prompt._id}
                              container={true}
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Button
                                key={index}
                                onClick={() =>
                                  handleDefaultPromptSelected(prompt)
                                }
                                className="change-default-prompt-select"
                              >
                                <Grid
                                  container={true}
                                  alignItems="flex-start"
                                  direction="column"
                                >
                                  {prompt.type !== 'preset'
                                    ? prompt.command
                                    : prompt.message}
                                  <Text textStyle="body2">
                                    Type: {prompt.type}
                                  </Text>
                                  <Text textStyle="body2">
                                    Url: {prompt.url}
                                  </Text>
                                </Grid>
                              </Button>
                              {index === array.length - 1 ? null : (
                                <div className="change-default-prompt-select-divider" />
                              )}
                            </Grid>
                          )
                        },
                      )}
                    </Grid>
                  ) : (
                    <div onClick={closeDialog} className="no-other-prompts">
                      No other prompts found. Create a prompt to change your
                      default prompt.
                    </div>
                  )}
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </Grid>
        </Dialog>

        {/* SUCCESS SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={successSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbar}
        >
          <SnackbarContent
            message={
              <span id="client-snackbar">
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {snackbarMessage}
              </span>
            }
            style={divStyle.snackbar}
            aria-describedby="client-snackbar"
          />
        </Snackbar>

        {/* ERROR SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={errorSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbar}
        >
          <SnackbarContent
            message={
              <span id="client-snackbar">
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {snackbarMessage}
              </span>
            }
            style={divStyle.errorSnackbar}
            aria-describedby="client-snackbar"
            action={[
              <Close
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={closeSnackbar}
              />,
            ]}
          />
        </Snackbar>
        <SaveDetectorConfirmation
          hasUnsavedChanges={hasUnsavedChanges}
          onSave={handleSave}
          left={store.session.isNavDrawerOpen ? '350px' : '71px'}
          width={
            store.session.isNavDrawerOpen
              ? 'calc(100% - 350px)'
              : 'calc(100% - 71px)'
          }
        />
      </Box>
    )
  } else if (params.botId) {
    return (
      <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
    ) // TODO show a better loading screen
  }

  return <Navigate to="/" />
}

export default withRouter(withStyles(styles)(observer(BotPrompts)))
